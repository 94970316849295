<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2 style="text-align:center">
            {{ currentStream.name }}
          </h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="0" md="2" class="hidden-sm-and-down" />
        <v-col cols="12" md="8">
          <v-list>
            <v-list-item>
              <custom-card
                  @click="createMessageDialog = true"
                  width="100%"
                  style="margin-bottom: 1em"
              >
                <v-card-title>
                  Create a Message
                </v-card-title>
              </custom-card>
            </v-list-item>
            <v-list-item v-if="messages.length === 0">
              <custom-card
                width="100%"
                >
                <v-card-title>
                  No messages found...
                </v-card-title>
              </custom-card>
            </v-list-item>
            <v-list-item v-for="message in messages" :key="message.messageid">
              <custom-card
                  width="100%"
                  style="margin-bottom: 0.5em"
              >
                <v-card-text style="padding-bottom: 10px">
                  {{ message.bodyjson }}
                  <!-- TODO: relative date, edit, actions -->
                </v-card-text>
                <v-card-actions style="padding-bottom:5px">
                  <small>{{ message.ts }}</small>
                  <v-spacer />
                  <!-- TODO: buttons... -->
                </v-card-actions>
              </custom-card>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="0" md="2" class="hidden-sm-and-down" />
      </v-row>
    </v-container>
    <v-dialog
        persistent
        v-model="createMessageDialog"
    >
      <v-card>
        <v-card-title>
          Create Message
        </v-card-title>
        <v-card-text>
          <!-- TODO: structural data, jsonSchema and such! -->
          <v-textarea
            v-model="newMessage"
            label="Message Text"
          ></v-textarea>
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="newMessageDate"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
                v-model="newMessageDate"
                no-title
                scrollable
                @input="menu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="createMessageDialog = false">Cancel</v-btn>
          <v-btn @click="submitMessage">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {Action as StreamAction, Getter as StreamGetter} from '@/store/stream/types'
import CustomCard from "@/components/app/CustomCard";
export default {
  name: "Stream",
  components: {CustomCard},
  data: () => ({
    streamGuid: null,
    newMessage: '',
    // TODO: a way to honor the user's time-zone?
    newMessageDate: new Date().toISOString().substr(0,10),
    createMessageDialog: false,
    menu: false,
  }),
  methods: {
    submitMessage() {
      const newMessageTs = this.newMessageDate // + 'T00:00:00'
      let res = this.$store.dispatch(
          `${[StreamAction.PostMessage]}`,
          {streamGuid: this.streamGuid, message: this.newMessage, ts: newMessageTs})
      res.then(
          () => {
            this.createMessageDialog = false
            this.newMessage = ''
          }
      )
    },
  },
  computed: {
    currentStream() {
      return this.$store.getters[`${[StreamGetter.GetCurrentStream]}`]
    },
    messages() {
      return this.$store.getters[`${[StreamGetter.GetMessages]}`]
    },
  },
  mounted() {
    // TODO: check for guid is valid...?
    this.streamGuid = this.$route.params.guid
    this.$store.dispatch(`${[StreamAction.FetchMessages]}`, {streamGuid: this.streamGuid})
    this.$store.dispatch(`${[StreamAction.FetchCurrentStream]}`, {streamGuid: this.streamGuid})
  },
}
</script>

<style scoped>

</style>
